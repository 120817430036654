



















































import { Component, Vue } from 'vue-property-decorator'

import Loader from '@/components/ui/Loader/Loader.vue'
import Timeline from '@/components/ui/Timeline/Timeline.vue'

import ForecastMix from '@/components/data/forecast/ForecastMix.vue'

import { vrsStoreForecast } from '@/store/forecast/store'
import { vrsStoreEvent } from '@/store/event/store'
import { vrsStoreApp } from '@/store/app/store'
import { vrsStoreConfig } from '@/store/config/store'
import { vrsStore, fetchForecastDataGlobal } from '@/store'

import { StateForecast } from '@/store/forecast/definitions'
import { StateApp } from '@/store/app/definitions'

import MixinPageVisibility from '@/mixins/pageVisibility'
import { PeriodOfInterest } from '@/components/ui/Timeline/D3Timeline/definitions'

@Component({
  components: {
    'ui-loader': Loader,
    'ui-timeline': Timeline,
    'forecast-mix': ForecastMix
  },
  mixins: [
    MixinPageVisibility((isVisible) => {
      if (isVisible && vrsStoreApp.state.data.live) {
        fetchForecastDataGlobal()
      }
    })
  ]

})
export default class Forecast extends Vue {
  stateForecast: StateForecast = vrsStoreForecast.state
  stateEvent = vrsStoreEvent.state
  stateApp: StateApp = vrsStoreApp.state
  stateConfig = vrsStoreConfig.state

  get availableTimeRangesLocal () {
    if (!this.stateForecast.apiData?.availableRanges) return []
    return [this.stateForecast.apiData?.availableRanges]
  }

  get allDataTimeRangesLocal () {
    return vrsStore.computed.allDataTimeRangesLocal()
  }

  updateReferenceDate (newTime) {
    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        referenceDate: newTime,
        mode: 'archive'
      }
    }).catch(() => {
    })
  }

  updateZoom (newZoom) {
    if (!newZoom) return
    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        zoomTimeline: newZoom
      }
    }).catch(() => {
    })
  }

  goLive () {
    const newRoute = {
      path: this.$route.path,
      query: {
        ...this.$route.query
      }
    }
    delete newRoute.query.mode
    /**
     * if the event is an active one,
     * we just remove the referenceDate,
     * that will compute (in store, later)
     * the good reference date, for a live event
     */
    if (this.stateEvent.data.active) {
      delete newRoute.query.referenceDate
    } else {
      /**
       * if the event is not an active one,
       * we try to go to the latest date available
       */
      if (this.stateForecast.apiData?.availableRanges?.data?.length > 0) {
        newRoute.query.referenceDate = this.stateForecast.apiData.availableRanges.data[
          this.stateForecast.apiData.availableRanges.data.length - 1
        ][1]
      } else {
        /**
         * if there is no one, we just go to the latest date of the event period
         */
        newRoute.query.referenceDate = this.stateEvent.data.dateRange[1]
      }
    }
    this.$router
      .push(newRoute)
      .catch(() => {
      })
  }

  goPast () {
    const newRoute = {
      path: this.$route.path,
      query: {
        ...this.$route.query
      }
    }
    /**
     * if the event is not an active one,
     * we remove the referenceDate,
     * that will compute the right one (in store, later)
     * to the first available data
     */
    if (!this.stateEvent.data.active) {
      delete newRoute.query.referenceDate
    } else {
      /**
       * else we are on an active one,
       * so we try to find the first data date,
       * or we set to the first date of the event period
       */
      if (this.stateForecast.apiData?.availableRanges?.data?.length > 0) {
        newRoute.query.referenceDate = this.stateForecast.apiData.availableRanges.data[0][0]
      } else {
        newRoute.query.referenceDate = this.stateEvent.data.dateRange[0]
      }
    }
    this.$router
      .push(newRoute)
      .catch(() => {
      })
  }

  goSession (session: PeriodOfInterest) {
    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        referenceDate: session.start
      }
    })
  }

  mounted () {
    fetchForecastDataGlobal()
  }
}

